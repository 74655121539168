import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const impressum = () => {
    return (
        <Layout nav='fixed'>
            <SEO title='Impressum' description='Impressum' />
            <div className="impressum">
                <div className="container">
                    <h2 className="impressum__heading">
                        Impressum
                    </h2>
                    <div className="impressum__content">
                        <p className="bold">
                            Copyright
                        </p>
                        <p>
                            Fit ó Fago <br/> Hauptstraße 37 <br/> B - 4780 Sankt Vith
                        </p>
                        <p>
                            Tel. +32 470 85 28 85 <br/> E-Mail: hello@fitofago.com
                        </p>
                        <p>
                            MwSt Nr.: BE 0749925113
                        </p>
                        <p className="bold">
                            Webdesign
                        </p>
                        <p>
                            Vanessa Trantes <br/> www.vanessatrantes.com
                        </p>
                        <p className="bold">
                            Implementation
                        </p>
                        <p>
                            Valudio SL <br /> info@valudio.com <br /> www.valudio.com
                        </p>
                        <p className="bold">
                            Fotos
                        </p>
                        <p>
                            Verena Mertes
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default impressum;